<template>
    <b-card-code>
        <b-card-body>
            <h2 class="text-primary">
                Dimensions / Cost Objects / Key Data Tables
            </h2>
            <b-card-text class="mb-1">
                <span>Vivamus in suscipit nisl. Praesent rutrum vestibulum suscipit. Nullam at lacus id mauris fringilla imperdiet at in purus. Vivamus pellentesque ipsum in ultrices porttitor.</span>
            </b-card-text>
        </b-card-body>
        <b-row>
            <b-col
                cols="12"
                md="2"
                class="d-flex justify-content-center"
            >
                <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                >
                <span class="align-middle mr-50">Undo</span>
                <feather-icon
                    icon="CornerUpLeftIcon"
                />
                </b-button>
            </b-col>
            <b-col
                cols="12"
                md="2"
            >
                <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                >
                <span class="align-middle mr-50">Create New</span>
                <feather-icon
                    icon="PlusCircleIcon"
                />
                </b-button>
            </b-col>
            <b-col
                class="d-flex justify-content-end align-items-baseline"
                cols="12"
                md="8"
            >
                <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="dark"
                >
                <feather-icon
                    icon="SaveIcon"
                    class="mr-50"
                />
                <span class="align-middle">Save</span>
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" md="12">
                <div id="tree" ref="tree"></div>
            </b-col>
        </b-row>
    </b-card-code>
</template>

<script>

import OrgChart from '@balkangraph/orgchart.js'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BRow, BCol, BCardBody, BCardText, BButton } from 'bootstrap-vue'

export default {
    components: {
        BCardCode,
        BRow,
        BCol,
        BCardBody, 
        BCardText,
        BButton,
    },
    name: 'tree',
    data() {
        return {
            nodes: [
                { id: 1, title: "Dimension X" },

                { id: 2, pid: 1, title: "Branch", img: "https://cdn.balkan.app/shared/empty-img-none.svg" },
                { id: 3, pid: 1, title: "Order", img: "https://cdn.balkan.app/shared/empty-img-none.svg"},
                { id: 4, pid: 2, title: "Dimesion Y" },

                { id: 5, pid: 1, title: "Invoice", img: "https://cdn.balkan.app/shared/empty-img-none.svg" },
                { id: 6, pid: 1, title: "Customer", img: "https://cdn.balkan.app/shared/empty-img-none.svg" },
                { id: 7, pid: 5, title: "Invoice Line" },

                { id: 8, pid: 1, title: "Product/Service", img: "https://cdn.balkan.app/shared/empty-img-none.svg" },
            ]
        }
    },

    methods: {
        mytree: function(domEl, x) {
            this.chart = new OrgChart (domEl, {
                mouseScrool: OrgChart.none,
                template: "olivia",
                nodeMouseClick: OrgChart.action.none,
                enableSearch: false,
                nodes: x,
                nodeBinding: {
                    field_0: "title",
                    img_0: "img"
                },
                nodeMenu: {
                    edit: { text: "Edit" },
                    remove: { text: "Remove" }
                },
            });
        }
    },

    mounted(){
        this.mytree(this.$refs.tree, this.nodes)
    }
}
</script>

<style scoped>
</style>